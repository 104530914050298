import BrowserPoniesBaseConfig from '@utils/basecfg'
import BrowserPonies from '@utils/browserponies'
// const BrowserPoniesBaseConfig = require('https://physbam.stanford.edu/physiqual/Browser-Ponies/basecfg.js')
// const BrowserPonies = require('https://physbam.stanford.edu/physiqual/Browser-Ponies/browserponies.js')

const config = {
    baseurl: "https://physbam.stanford.edu/physiqual/Browser-Ponies/",
    fadeDuration: 500,
    volume: 1,
    fps: 25,
    speed: 3,
    audioEnabled: false,
    showFps: false,
    showLoadProgress: true,
    speakProbability: 0.1,
    spawn: {
        "applejack": 1,
        "fluttershy": 1,
        "pinkie pie": 1,
        "rainbow dash": 1,
        "rarity": 1,
        "twilight sparkle": 1,
    },
    autostart: true,
}

const initPonies = () => {
    BrowserPonies.setBaseUrl(config.baseurl);
    BrowserPonies.loadConfig(BrowserPoniesBaseConfig);
    BrowserPonies.loadConfig(config);
}

export  {
    initPonies,
}