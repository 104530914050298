import { React } from 'react'
import { Text } from 'react-native-paper'
import { Row, Stack } from '@components'
import { Size } from '@utils/stack'

const Leaderboard = () => {
    
    return (
        <Stack>
            
        </Stack>
    )
}

export default Leaderboard